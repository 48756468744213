import * as React from "react"
import '../../css/bootstrap.css';
import {ThemeProvider} from "react-bootstrap";

import "../../fonts/font-awesome/css/font-awesome.min.css";
import "../../css/template.css";
import "../../css/responsive.css";
import "../../css/base-sizing.css";
import "../../css/custom.css";
import "../../css/knowledge.css";

import HeaderAndMenu from "../../page_components/common/HeaderAndMenu";
import Footer from "../../page_components/common/Footer";
import BsHelmet from "../../page_components/common/BsHelmet";
import CommonHeroLow from "../../page_components/common/CommonHeroLow";
import BreadCrumbs from "./breadcrumbs";
import {StaticImage} from "gatsby-plugin-image";
import {useEffect} from "react";
import initStickyMenu from "../../page_components/common/initStickyMenu";


const Balances = () => {
    useEffect(() => {
        initStickyMenu();
    }, []);

    return (
        <ThemeProvider breakpoints={['xxxl', 'xxl', 'xl', 'lg', 'md', 'sm', 'xs', 'xxs']}>
            <BsHelmet title={"BitSpreader Documentation - Balances"}/>
            <div id="page_wrapper">
                <HeaderAndMenu/>
                <CommonHeroLow title={"BitSpreader Documentation - Balances"}/>
                <BreadCrumbs link={"balances"} title="Balances"/>
                <section className="hg_section">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-4">
                                <div id="stickymenu" className="sidebar knowledgebase">
                                    <div className="widget widget_categories">
                                        <h3 className="widgettitle title">SECTIONS</h3>
                                        <a href="#balances">Balances</a>
                                    </div>
                                </div>
                            </div>
                            <div className={"col-md-8"}>
                                <h2 id="stickyref" className="kb-article--title">
                                    <a name={"apikeys"}>Balances</a>
                                </h2>

                                <div className={"row"}>
                                    <div className={'col-md-6 text-justify'}>
                                        <br/>
                                        After providing API Keys to exchanges BitSpreader will pull the wallet data from the exchanges to which you have provided the API Keys. You will be able to
                                        access the portfolio summary as well as detailed information about each wallet by clicking on the “Balances” option in the left hand sliding sidebar.

                                    </div>
                                    <div className={'col-md-6 text-center'}>
                                        <StaticImage src={"../../gatsbyimg/docs/openbalances.png"} alt={"Registration#1"} className={"border"} width={"200"}/>
                                    </div>
                                </div>
                                <div className={"row"}>
                                    <div className={'col-md-12 pt-20'}>
                                        <StaticImage src={"../../gatsbyimg/docs/balances.png"} alt={"Registration#1"} className={"border"}/>
                                    </div>
                                </div>
                                <br/><br/>

                                <div className={"row"}>
                                    <div className={'col-md-12 text-justify'}>
                                        <br/>
                                        The top component of this screen contains summary information for all the exchanges and respective wallets to which you have provided the API Keys - sum of all
                                        the assets on all the wallets. Below you can see a breakdown of the wallets by the exchange.
                                        <br/><br/>
                                        Certain portion of the the balances will be removed from the list in case you remove an API key to given exchange


                                    </div>
                                </div>

                            </div>

                        </div>
                    </div>
                </section>
                <Footer/>
            </div>
        </ThemeProvider>
    )
}

export default Balances;
